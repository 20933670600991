export enum UserRoles {
  ADMIN = "ADMIN",
  COMPANY = "COMPANY",
  CANDIDATE = "CANDIDATE",
  STUDENT = "STUDENT",
  FUNTRAB = "FUNTRAB",
  SEMADESC = "SEMADESC",
  ADMIN_IEP = "ADMIN_IEP",
  SECRETARY_IEP = "SECRETARY_IEP",
}
