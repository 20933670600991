import { createSvgIcon } from "@mui/material"

export const IEPIcon = createSvgIcon(
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.00012207" width="32" height="32" rx="16" fill="#CFDCED" />
    <rect x="4" y="4.00012" width="24" height="24" rx="12" fill="#004F9F" />
    <path
      d="M9.73867 12.8907V20.0001H8.3959V12.8907H9.73867ZM16.3008 18.9454V20.0001H12.5215V18.9454H16.3008ZM12.9316 12.8907V20.0001H11.5889V12.8907H12.9316ZM15.8076 15.8253V16.8556H12.5215V15.8253H15.8076ZM16.2861 12.8907V13.9503H12.5215V12.8907H16.2861ZM20.3678 17.4073H18.5367V16.3527H20.3678C20.6673 16.3527 20.9098 16.3038 21.0953 16.2062C21.2841 16.1053 21.4225 15.9685 21.5104 15.796C21.5982 15.6202 21.6422 15.42 21.6422 15.1954C21.6422 14.9773 21.5982 14.7739 21.5104 14.5851C21.4225 14.3963 21.2841 14.2433 21.0953 14.1261C20.9098 14.0089 20.6673 13.9503 20.3678 13.9503H18.9762V20.0001H17.6334V12.8907H20.3678C20.9212 12.8907 21.3932 12.99 21.7838 13.1886C22.1777 13.3839 22.4771 13.6557 22.6822 14.004C22.8906 14.3491 22.9947 14.743 22.9947 15.1857C22.9947 15.6447 22.8906 16.0402 22.6822 16.3722C22.4771 16.7042 22.1777 16.9598 21.7838 17.1388C21.3932 17.3178 20.9212 17.4073 20.3678 17.4073Z"
      fill="white"
    />
  </svg>,
  "IEPIcon",
)
