import { FUNTRABIcon } from "@/components/ui/custom-icon/FUNTRABIcon"
import { IEPIcon } from "@/components/ui/custom-icon/IEPIcon"
import { MSArmsIcon } from "@/components/ui/custom-icon/MSArmsIcon"
import { EnumData } from "@/interfaces/EnumData"
import { UserRoleAttributes, userRoleConsts } from "@/interfaces/constants/UserRoleConsts"
import { UserRoles } from "@/interfaces/enums/UserRoles"
import { CurrentProfile } from "@/interfaces/profile/CurrentProfile"
import { ProfileOutput } from "@/interfaces/profile/ProfileOutput"
import { AdminPanelSettings, Business, School } from "@mui/icons-material"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"

/**
 *  Get the profile icon
 * @param profile  CurrentProfile | null
 * @returns  JSX.Element | null  Profile icon
 */
export function getProfileIcon(profile: CurrentProfile | null) {
  if (isCandidateProfileRole(profile)) {
    return <AccountCircleIcon />
  } else if (isStudentProfileRole(profile)) {
    return <School />
  } else if (isCompanyProfileRole(profile)) {
    return <Business />
  } else if (isFuntrabProfileRole(profile)) {
    return <FUNTRABIcon />
  } else if (isSemadescProfileRole(profile)) {
    return <MSArmsIcon />
  } else if (isADMINProfileRole(profile)) {
    return <AdminPanelSettings />
  } else if (isAdminIepProfileRole(profile) || isSecretaryIepProfileRole(profile)) {
    return <IEPIcon />
  }
  return null
}

/**
 * Get the profile icon
 */
export const UserRolesIcon: { [key in UserRoles]: JSX.Element } = {
  [UserRoles.ADMIN]: <AdminPanelSettings />,
  [UserRoles.COMPANY]: <Business />,
  [UserRoles.CANDIDATE]: <AccountCircleIcon />,
  [UserRoles.STUDENT]: <School />,
  [UserRoles.FUNTRAB]: <FUNTRABIcon />,
  [UserRoles.SEMADESC]: <MSArmsIcon />,
  [UserRoles.ADMIN_IEP]: <IEPIcon />,
  [UserRoles.SECRETARY_IEP]: <IEPIcon />,
}

/**
 *  Get the profile name
 * @param profile  CurrentProfile | null
 * @returns  string   Profile name
 */
export function getProfileTextName(profile: CurrentProfile | null) {
  if (profile?.role?.value) {
    switch (profile.role.value) {
      case UserRoles.CANDIDATE:
        return "CANDIDATO"
      case UserRoles.STUDENT:
        return "ALUNO"
      case UserRoles.COMPANY:
        return "EMPRESA"
      case UserRoles.FUNTRAB:
        return "FUNTRAB"
      case UserRoles.SEMADESC:
        return "SEMADESC"
      case UserRoles.ADMIN:
        return "ADMIN"
      case UserRoles.ADMIN_IEP:
        return "ADMIN IEP"
      case UserRoles.SECRETARY_IEP:
        return "SECRETARIA IEP"
      default:
        return ""
    }
  }
  return ""
}

/**
 *  Check if the profile is a company profile
 * @param profile   CurrentProfile | null
 * @returns   boolean   True if the profile is a company profile
 */
export function isCompanyProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.COMPANY
}

/**
 *  Check if the profile is a candidate profile
 * @param profile   CurrentProfile | null
 * @returns   boolean   True if the profile is a candidate profile
 */
export function isCandidateProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.CANDIDATE
}

/**
 *  Check if the profile is a ADMIN_MASTER profile
 * @param profile   CurrentProfile | null
 * @returns   boolean   True if the profile is a ADMIN_MASTER profile
 */
export function isADMINProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.ADMIN
}

/**
 *  Check if the profile has a FUNTRAB profile
 * @param profiles  List of profiles
 * @returns   boolean   True if the profile has a FUNTRAB profile
 */
export function hasFuntrabProfileRole(profiles: ProfileOutput[] | undefined) {
  return profiles?.some((profile) => profile.role.value === UserRoles.FUNTRAB) || false
}

/**
 *  Check if the profile has a ADMIN_FUNTRAB profile
 * @param subRoles  List of subroles
 * @returns   boolean True if the profile has a ADMIN_FUNTRAB profile
 */
export function hasAdminFruntrabSubRole(subRoles: EnumData[] | undefined) {
  return subRoles?.some((subrole) => subrole.value === "ADMIN_FUNTRAB") || false
}

/**
 *  Check if the profile is a FUNTRAB profile
 * @param profile  CurrentProfile | null
 * @returns  boolean   True if the profile is a FUNTRAB profile
 */
export function isFuntrabProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.FUNTRAB
}

/**
 *  Check if the profile has a SEMADESC profile
 * @param profiles  List of profiles
 * @returns   boolean   True if the profile has a SENADESC profile
 */
export function hasSemadescProfileRole(profiles: ProfileOutput[] | undefined) {
  return profiles?.some((profile) => profile.role.value === UserRoles.SEMADESC) || false
}

/**
 *  Check if the profile has a ADMIN_IEP profile
 * @param profiles  List of profiles
 * @returns   boolean   True if the profile has a ADMIN_IEP profile
 */
export function hasAdminIepProfileRole(profiles: ProfileOutput[] | undefined) {
  return profiles?.some((profile) => profile.role.value === UserRoles.ADMIN_IEP) || false
}

/**
 *  Check if the profile has a SECRETARY_IEP profile
 * @param profiles  List of profiles
 * @returns   boolean   True if the profile has a SECRETARY_IEP profile
 */
export function hasSecretaryIepProfileRole(profiles: ProfileOutput[] | undefined) {
  return (
    profiles?.some((profile) => profile.role.value === UserRoles.SECRETARY_IEP) || false
  )
}

/**
 *  Check if the profile is a ADMIN profile
 * @param profiles  List of profiles
 * @returns   boolean   True if the profile is a ADMIN profile
 */
export function hasADMINProfileRole(profiles: ProfileOutput[] | undefined) {
  return profiles?.some((profile) => profile.role.value === UserRoles.ADMIN) || false
}

/**
 *  Check if the profile has a student profile
 * @param profiles  List of profiles
 * @returns   boolean   True if the profile has a student profile
 */
export function hasStudentProfileRole(profiles: ProfileOutput[] | undefined) {
  return profiles?.some((profile) => profile.role.value === UserRoles.STUDENT) || false
}

/**
 *  Check if the profile is a student profile
 * @param profile  CurrentProfile | null
 * @returns  boolean   True if the profile is a student profile
 */
export function isStudentProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.STUDENT
}

/**
 *  Check if the profile is a SEMADESC profile
 * @param profile  CurrentProfile | null
 * @returns  boolean   True if the profile is a SEMADESC profile
 */
export function isSemadescProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.SEMADESC
}

/**
 *  Check if the profile is a ADMIN_IEP profile
 * @param profile  CurrentProfile | null
 * @returns  boolean   True if the profile is a ADMIN_IEP profile
 */
export function isAdminIepProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.ADMIN_IEP
}

/**
 *  Check if the profile is a SECRETARY_IEP profile
 * @param profile  CurrentProfile | null
 * @returns  boolean   True if the profile is a SECRETARY_IEP profile
 */
export function isSecretaryIepProfileRole(profile: CurrentProfile | null) {
  return profile?.role?.value === UserRoles.SECRETARY_IEP
}

export function getProfileById(
  profiles: CurrentProfile[] | undefined,
  profileId: string = "",
) {
  if (!profiles || !profileId) return null
  if (
    profileId === UserRoles.ADMIN ||
    profileId === UserRoles.SEMADESC ||
    profileId === UserRoles.STUDENT ||
    profileId === UserRoles.ADMIN_IEP ||
    profileId === UserRoles.SECRETARY_IEP
  )
    return profiles.find((profile) => String(profile.role.value) === String(profileId))

  return profiles.find((profile) => String(profile.id) === String(profileId))
}

/**
 *  Get the highest weighted role from a list of profiles
 * @param profiles List of profiles
 * @returns UserRoleAttributes | null
 */
export function getHighestWeightedRole(
  profiles: ProfileOutput[],
): UserRoleAttributes | null {
  if (profiles.length === 0) return null

  let highestWeightedRole: UserRoles | null = null
  let highestWeight = -Infinity

  profiles.forEach((profile) => {
    const role = profile.role.value as UserRoles
    const weight = userRoleConsts[role]?.weight ?? -Infinity

    if (weight > highestWeight) {
      highestWeightedRole = role
      highestWeight = weight
    }
  })
  if (highestWeightedRole === null) return null
  return userRoleConsts[highestWeightedRole]
}

/**
 *  Get the default panel of the highest weighted role from a list of profiles
 * @param profiles  List of profiles
 * @returns  string | null
 */
export function getDefaultPanelOfHighestWeightedRole(
  profiles: ProfileOutput[],
): string | null {
  if (profiles.length === 0) return null

  let highestWeightedRole: UserRoles | null = null
  let highestWeight = -Infinity

  profiles.forEach((profile) => {
    const role = profile.role.value as UserRoles
    const roleAttributes = userRoleConsts[role]
    const weight = roleAttributes?.weight ?? -Infinity
    const defaultPanel = roleAttributes?.defaultPanel

    if (weight > highestWeight && defaultPanel) {
      highestWeightedRole = role
      highestWeight = weight
    }
  })

  if (highestWeightedRole === null) return null

  const highestRoleAttributes = userRoleConsts[highestWeightedRole] as UserRoleAttributes
  if (!highestRoleAttributes) return null

  return highestRoleAttributes.defaultPanel || null
}
